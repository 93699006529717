// Clears any type of form field, resetting it to empty or unselected
export const clearField = (field) => {
  if (field.tagName === 'INPUT') {
    if (field.type === 'radio' || field.type === 'checkbox') {
        field.checked = field.defaultChecked;
    } else {
        field.value = '';
    }
  } else if (field.tagName === 'SELECT') {
      field.selectedIndex = -1; // Clear selection
  } else if (field.tagName === 'TEXTAREA') {
      field.value = '';
  } else if (field.tagName === 'DIV') {
    // This handles DropdownComponent fields
    const selectField = field.querySelector('select');
    if (selectField != null) {
        selectField.value = null;
        selectField.selectedIndex = -1;
    }
    const textElement = field.querySelector('.text');
    if (textElement != null) {
        textElement.innerText = i18n.t('placeholders.please_select');
    }
  } else if (field.tagName === "BUTTON") {
    // Filestack is a button, with other elements.
    // It is managed by a stimulus controller, so we need to dispatch an event to clear it.
    if (field.parentElement.dataset.controller === "filestack") {
      field.parentElement.dispatchEvent(new CustomEvent("filestack:clearfiles"));
    }
  }
};