import { Controller } from "@hotwired/stimulus";
import {
  getFilestackUrlForFile,
  defaultFilestackOptions,
  filestackAllowedImageTypes,
  initFilestackClient,
} from "../src/plugins/Filestack";

export default class extends Controller {
  static targets = [
    "attachmentsInput",
    "attachmentsList",
    "attachmentsListTemplate",
  ];

  // This potentially double decodes the collection
  // Most places that use this controller will JSON.parse the input before saving it
  // Custom questions / responses don't have a great way to inject a parse so it is saved as
  // a string and then parsed here.
  attachmentsValue() {
    if (this.attachmentsInputTarget.value) {
      var value = JSON.parse(this.attachmentsInputTarget.value);
      if (typeof value === "string") {
        return JSON.parse(value);
      } else {
        return value;
      }
    } else {
      return [];
    }
  }

  renderFileList() {
    if (
      this.attachmentsListTarget == null ||
      typeof this.attachmentsValue() !== "object"
    ) {
      return;
    }

    this.attachmentsListTarget.innerHTML = "";

    this.attachmentsValue().forEach((file) => {
      if (file.isDeleted) {
        return;
      }

      const clone =
        this.attachmentsListTemplateTarget.content.firstElementChild.cloneNode(
          true,
        );
      // object format from filestack is different than attachment from DB so we need to handle both
      clone.dataset.filename = file.filename || file.file_file_name;
      clone.dataset.handle = file.handle || file.file_handle;
      clone.dataset.href = getFilestackUrlForFile(file);
      this.attachmentsListTarget.appendChild(clone);
    });

    if (
      this.attachmentsValue().filter((elem) => {
        return !elem.isDeleted;
      }).length > 0
    ) {
      this.attachmentsListTarget.classList.remove("tw-hidden");
    } else {
      this.attachmentsListTarget.classList.add("tw-hidden");
    }
  }

  removeFile(event) {
    event.preventDefault();

    const newValue = [];
    // If the file has an ID, mark it as deleted
    // If it doesn't, just remove it from the list
    this.attachmentsValue().forEach((elem) => {
      const elemHandle = elem.handle || elem.file_handle;
      if (elemHandle !== event.target.dataset.handle) {
        newValue.push(elem);
      } else if (elem.id != null) {
        elem.isDeleted = true;
        newValue.push(elem);
      }
    });

    this.attachmentsInputTarget.value = JSON.stringify(newValue);
    this.renderFileList();
  }

  clearFiles() {
    const newValue = [];
    this.attachmentsValue().forEach((elem) => {
      if (elem.id != null) {
        elem.isDeleted = true;
        newValue.push(elem);
      }
    });

    this.attachmentsInputTarget.value = JSON.stringify(newValue);
    this.renderFileList();
  }

  onUploadDone(data) {
    if (data.filesUploaded.length > 0) {
      const fieldValue = this.attachmentsValue();
      const existingIds = fieldValue.map((v) => v.uploadId);
      data.filesUploaded.forEach((file) => {
        if (existingIds.indexOf(file.uploadId) === -1) {
          fieldValue.push(file);
        }
      });
      this.attachmentsInputTarget.value = JSON.stringify(fieldValue);
    }
    this.renderFileList();
  }

  openPicker() {
    const options = JSON.parse(this.element.dataset.filestackOptions || "{}");
    options.maxFiles = parseInt(options.maxFiles || "1", 10);

    if (
      options.transformations != null &&
      options.transformations.crop != null
    ) {
      delete options.transformations;
    }

    options.onUploadDone = this.onUploadDone.bind(this);

    const defaults = defaultFilestackOptions();
    const updatedOptions = { ...defaults, ...options };
    // Do not allow SVG as it can include embedded JS.
    if (updatedOptions.accept.indexOf("image/*") >= 0) {
      updatedOptions.accept = filestackAllowedImageTypes;
    }

    if (!updatedOptions.onOpen) {
      updatedOptions.onOpen = () => {
        this.dispatch("show");
      };
    }

    if (!updatedOptions.onClose) {
      updatedOptions.onClose = () => {
        this.dispatch("hide");
      };
    }

    window.fpGroupize.picker(updatedOptions).open();
  }

  connect() {
    if (this.attachmentsInputTarget == null) {
      return;
    }

    if (!window.fpGroupize) {
      initFilestackClient();
    }

    this.element.classList.remove("tw-hidden");
    this.renderFileList();

    // Clearing the file list may be triggered by normal javascript so we need to
    // listen for a custom event
    this.element.addEventListener("filestack:clearfiles", this.clearFiles.bind(this));
  }
}
