import { Controller } from "@hotwired/stimulus";
import { i18n } from "../src/custom/Internationalization";
import { clearField } from "../src/custom/form_helpers";

export default class extends Controller {
  static targets = ["control", "conditionalQuestion"];

  connect() {
    if (this.controlTargets.length > 0) {
      this.controlTargets.forEach((control) => {
        if (control.nodeName === "SELECT") {
          this.displayChildren(control.value, false);
          this.hideChildren(control.options, control.value);
        }
      });
    }
    document.dispatchEvent(new Event("rebind-validation"));
  }

  subQuestionDisplay(event) {
    const select = event.currentTarget;
    this.displayChildren(select.value, false);
    this.hideChildren(select.options, select.value);
    document.dispatchEvent(new Event("rebind-validation"));
  }

  displayChildren(answerId, clearValue) {
    this.conditionalQuestionTargets.forEach((question) => {
      if (question.dataset.parentAnswerId === answerId) {
        question.classList.remove("tw-hidden");
        if (clearValue === true) {
          this.resetField(question);
        }

        // This direct call works if the question element IS the form field itself
        this.setRequired(question);
      }
    });
  }

  setRequired(field) {
    if (field.dataset.conditionallyRequired === "true") {
      field.dataset.formValidate = true;
      if (field.getAttribute("type") !== "checkbox") {
        field.dataset.formValidateRequired = true;
      }
      field.dataset.justValidateFallbackDisabled = false;
    }

    field.querySelectorAll("[data-form-validate-required], [data-conditionally-required]").forEach((nestedField) => {
      this.setRequired(nestedField);
    });
  }

  setNotRequired(field) {
    field.dataset.justValidateFallbackDisabled = true;
    field.dataset.formValidate = false;
    if (field.getAttribute("type") !== "checkbox") {
      field.dataset.formValidateRequired = false;
    }

    field.querySelectorAll("[data-form-validate-required], [data-conditionally-required]").forEach((nestedField) => {
      this.setNotRequired(nestedField);
    });
  }

  hideDescendents(parentId) {
    this.conditionalQuestionTargets.forEach((question) => {
      if (question.dataset.parentIds.includes(parentId)) {
        question.classList.add("tw-hidden");
        this.resetField(question);
        this.setNotRequired(question);
      }
    });
  }

  hideChildren(options, selected) {
    const optionsArray = Array.from(options);
    if (optionsArray) {
      optionsArray.forEach((option) => {
        if (option.value && option.value !== selected) {
          this.conditionalQuestionTargets.forEach((select) => {
            if (select.dataset.parentAnswerId === option.value) {
              this.hideDescendents(select.dataset.questionId);
              select.classList.add("tw-hidden");
              this.resetField(select);
              this.setNotRequired(select);
            }
          }, this);
        }
      });
    }
  }

  resetField(wrapper) {
    // The field passed in is a wrapper, we want to get the actual form elements
    wrapper.querySelectorAll(".conditional-question").forEach((field) => {
      clearField(field);
    });
  }
}
